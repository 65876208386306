


























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { shortVid, splitVid } from '@/utils/vid'
import VeTrueApi from '@/api/vetrue';

@Component({
  components: {},
  filters:{}
})
export default class vidResultMulti extends Vue {

  public mode: string = 'one' // 单码 or 起止码

  public manualTag: string = '' // 单码 model

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  public dialogVisible: boolean = false
  public vidDetailDialogVisible: boolean = false
  public failRfidData: Array<any> = []
  public rfidData: Array<any> = []
  public currentVid: any = {}

  public customId: any = {
    begin: "",
    end: "",
    list: []
  }

  public timer: number = 0;
  public timeoutr: number = 0;

  public vidListData: Array<any> = []

  public get idType(): any {
    if(this.selectedScannerOption.startsWith('nfc') || this.selectedScannerOption.indexOf('customid') < -1 ){
      return this.$t('app.id_type_vid')
    }
    return this.$t('app.id_type_custom')
  }

  public get isIos(): boolean {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  public get isNFC(): boolean {
    return this.selectedScannerOption.length > 0 && this.selectedScannerOption.startsWith('nfc')
  }

  public get vidResultDialogClass(): string {
    return "scan-dialog"
  }

  // 切换设置 - 切换弹窗，显示设置弹窗
  public toggleSetting(): void {
    // this.clear();
    this.$emit('openSetting') // 显示设置弹窗，传函数方式
  }

  // methods
  public show(): void {
    this.dialogVisible = true
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }

  public getVidErrorMessage(vidCode: any | undefined){
    if(vidCode && vidCode !== 1){
      return this.$t("app.viderror." + vidCode)
    }
  }

  public clean(): void {
    this.rfidData = []
    this.failRfidData = []
    this.vidListData = []
    this.$emit("confirm", this.vidListData)
  }

  public dismiss(): void {
    this.dialogVisible = false
  }

  public shortShowVid(vid: string): string {
    return shortVid(vid)
  }

  public devidVid(vid: string): string {
    return splitVid(vid)
  }

  public deleteVid(vidObject: any) {
    this.rfidData = this.rfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    this.failRfidData = this.failRfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
  }

  public clearAll() {
    this.rfidData = []
    this.failRfidData = []
  }

  public scannedVidFromQrcode(vids: any) {
    this.appendVids(vids)
  }

  public appendVids(vids: any) {
    let filteredVidList = vids.filter((item: any) => {
      var existedVidList = this.rfidData.filter((vidObj: any) => {
        return vidObj.vid.toLowerCase() === item.vid.toLowerCase()
      })
      return existedVidList.length <= 0
    })
    this.rfidData = this.rfidData.concat(filteredVidList)
    if(this.rfidData.length > this.vidCountLimit){
      this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
    }
    this.failRfidData = this.rfidData.filter(item => {
      return item.code !== 1
    })
  }

  public showVidDetail(vid: any) {
    this.vidDetailDialogVisible = true
    this.currentVid = vid
  }

  public handleCloseDialog(): void {
    this.dialogVisible = false;
    this.manualTag = '';
    this.clearAll();
    this.customId.begin = '';
    this.customId.end = '';
  }

  // 切换模式时需要清空数据
  public handleChangeMode = (mode: string) => {
    this.clearAll();
    this.manualTag = '';
    this.customId.begin = '';
    this.customId.end = '';
  }

  // 判断是否已经存在输入vid
  public isVIdInclude(vid: string) {
    return this.rfidData.filter((item) => item.vid.split(",")[0] === vid.toLowerCase()).length !== 0;
  }

  // 手动输入单码
  public async handleIncreaseTag() {
    if (!this.manualTag) {
      const messageString = this.$t("app.please_entry_tag") as any
      this.$message({ message: messageString, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
    if (this.isVIdInclude(this.manualTag)) {
      const msg = this.$t("datapoint.add_error_id_has_been_used") as any;
      this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
    try {
      // 手动输入时传 custom_id_list
      const params = {
        query: this.$route.query,
        project_uuid: this.$route.query.project_uuid,
        custom_id_list: [this.manualTag],
        label_type: 'PRODUCT_CODE' // 商品标签    PRODUCT_CODE      批次       BATCH_CODE
      }
      const list = await VeTrueApi.getUsableTagList(params);

      if (Object.keys(list).length === 0) {
        const msg = this.$t("datapoint.add_id_dialog_end_error_id") as any;
        this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
        return
      }
      this.rfidData = list.map((item: any) => {
        return {
          vid: item.custom_id ? `${item.vid},${item.custom_id}`: item.vid, // 增加逻辑判断
          code: 1
        }
      });

      if (this.rfidData.length > this.vidCountLimit) {
        this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
      }
    } catch (error: any) {
      const { response } = error;
      const msg = response.data.detail[0].msg;
      this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
  }

  public clear() {
    this.dialogVisible = false
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    } else {
      this.rfidData = []
      this.failRfidData = []
    }
  }

  public delAllFailData() {
    this.rfidData = this.rfidData.filter(item => {
      return item.code === 1
    })
    this.failRfidData = []
  }

  public confirm() {
    this.vidListData = JSON.parse(JSON.stringify(this.rfidData))
    this.dialogVisible = false
    this.$emit("confirm", this.vidListData)
  }

  // lifecycle
  created() {

  }

  mounted() {
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }

  // public scannedBeginId() {
  //   if(this.customId.begin !== '') {
  //     if (this.timer) clearTimeout(this.timer);
  //     this.timer = setTimeout(() => {
  //       this.generateVidList()
  //     }, 1000)
  //   }
  // }

  // public scannedEndId() {
  //   if(this.customId.end !== '') {
  //     if (this.timeoutr) clearTimeout(this.timeoutr);
  //     this.timeoutr = setTimeout(() => {
  //       this.generateVidList()
  //     }, 1000)
  //   }
  // }

  public handleGetStartStopCode() {
    if(!this.customId.begin || !this.customId.end) {
      const messageString = this.$t("app.no_start_end_code") as any
      this.$message({ message: messageString, type: "error", offset: 200, duration: 1000, center: true });
      return
    } else {
      this.generateVidList();
    }
  }

  // 芯片起止码需要在扫描到起止码后调用接口来获取完整的起止码
  public async generateVidList() {
    if(!this.customId.begin || !this.customId.end) {
      return
    }

    const list = await VeTrueApi.getUsableTagList({
      query: this.$route.query,
      project_uuid: this.$route.query.project_uuid,
      start_custom_id: this.customId.begin,
      end_custom_id: this.customId.end,
      label_type: 'PRODUCT_CODE' // 商品标签    PRODUCT_CODE      批次       BATCH_CODE
    });

    if (Object.keys(list).length === 0) {
      const messageString = this.$t("app.no_code") as any
      this.$message({ message: messageString, type: "error", offset: 300, duration: 3000, center: true });
      return
    } else {
      this.rfidData = list.map((item: any) => {
        return {
          vid: item.custom_id ? `${item.vid},${item.custom_id}`: item.vid, // 增加逻辑判断
          code: 1
        }
      });

      if (this.rfidData.length > this.vidCountLimit) {
        this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
      }
    }
  }

}
