
























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { shortVid, splitVid } from '@/utils/vid'
import VeTrueApi from '@/api/vetrue';
import VidInput from '@/components/common/vidInput.vue'
import VidScanner from '@/components/common/vidScanner.vue'

@Component({
  components: {
    VidInput,
    VidScanner
  },
  filters:{}
})
export default class vidResultRange extends Vue {

  public mode: string = 'one' // 单码 or 起止码

  // props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  // data
  public data: Object = {}
  public dialogVisible: boolean = false
  public vidDetailDialogVisible: boolean = false
  public failRfidData: Array<any> = []
  public rfidData: Array<any> = []
  public currentVid: any = {}

  public customId: any = {
    begin: "",
    end: "",
    list: []
  }
  public vidListData: Array<any> = []

  public get isCustomIdRange(): boolean {
    return this.selectedScannerOption === 'qr.customid_range'
  }

  public get idType(): any {
    if(this.selectedScannerOption.startsWith('nfc') || this.selectedScannerOption.indexOf('customid') < -1){
      return this.$t('app.id_type_vid')
    }
    return this.$t('app.id_type_custom')
  }

  public get isIos(): boolean {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  public get isNFC(): boolean {
    return this.selectedScannerOption.length > 0 && this.selectedScannerOption.startsWith('nfc')
  }

  public get isScanQrSingle(): boolean {
    return this.selectedScannerOption.length > 0 && this.selectedScannerOption.startsWith('qr.customid_single')
  }

  public get vidResultDialogClass(): string {
    if(this.isCustomIdRange){
      return "scan-dialog-range"
    }
    return "scan-dialog"
  }

  // methods
  public show(): void {
    this.dialogVisible = true
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
      const storedExtendList = this.$store.state.storedExtendList || this.$store.state.extendList
      if(this.dcuKeyPath && storedExtendList){
        let existExtendObj = storedExtendList.filter((item: any) => {
          return item.keyPath === this.dcuKeyPath
        })
        this.customId.begin = existExtendObj[0] && existExtendObj[0].startCode
        this.customId.end = existExtendObj[0] && existExtendObj[0].endCode
      }
    }
  }
  public dismiss(): void {
    this.dialogVisible = false
  }

  public clean(): void {
    this.rfidData = []
    this.failRfidData = []
    this.customId.begin = ""
    this.customId.end = ""
    this.customId.list = []
    this.vidListData = []
    this.$emit("confirm", this.vidListData)
  }

  public shortShowVid(vid: string): string {
    return shortVid(vid)
  }

  public devidVid(vid: string): string {
      return splitVid(vid)
  }

  public clearAll() {
    this.rfidData = []
    this.failRfidData = []
    this.customId.list = []
    this.customId.begin = ""
    this.customId.end = ""
  }

  public handleCloseDialog(): void {
    this.dialogVisible = false;
    this.clearAll();
  }

  // 切换模式时需要清空数据
  public handleChangeMode = (mode: string) => {
    this.clearAll();
  }

  public showVidDetail(vid: any) {
    this.vidDetailDialogVisible = true
    this.currentVid = vid
  }

  public clear() {
    this.dialogVisible = false
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
      const storedExtendList = this.$store.state.storedExtendList || this.$store.state.extendList
      if (this.dcuKeyPath && storedExtendList) {
        let existExtendObj = storedExtendList.filter((item: any) => {
          return item.keyPath === this.dcuKeyPath
        })
        this.customId.begin = existExtendObj[0] && existExtendObj[0].startCode
        this.customId.end = existExtendObj[0] && existExtendObj[0].endCode
      }
    } else {
      this.rfidData = []
      this.failRfidData = []
      this.customId.begin = ""
      this.customId.end = ""
    }
  }

  // 切换设置 - 切换弹窗，显示设置弹窗
  public toggleSetting(): void {
    // this.clear();
    this.$emit('openSetting') // 显示设置弹窗，传函数方式
  }

  public deleteVid(vidObject: any) {
    this.rfidData = this.rfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    this.failRfidData = this.failRfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    if (this.rfidData.length <= 0) {
      const storedExtendList = this.$store.state.storedExtendList
      if (this.dcuKeyPath && storedExtendList) {
        let existExtendObj = storedExtendList.filter((item: any) => {
          return item.keyPath === this.dcuKeyPath
        })
        this.customId.begin = existExtendObj[0] && existExtendObj[0].startCode
        this.customId.end = existExtendObj[0] && existExtendObj[0].endCode
      } else {
        this.customId.begin = ""
        this.customId.end = ""
      }
    }
  }

  public delAllFailData() {
    this.rfidData = this.rfidData.filter(item => {
      return item.code === 1
    })
    this.failRfidData = []
  }

  public confirm() {
    this.vidListData = JSON.parse(JSON.stringify(this.rfidData))
    this.dialogVisible = false
    const startCodeObject = { keyPath: this.dcuKeyPath, startCode: this.customId.begin, endCode: this.customId.end }
    this.$store.state.extendList = [startCodeObject]
    this.$emit("confirm", this.vidListData)
  }

  // custom_id_range
  public scannedBeginId(vids: any) {
    if(vids.length > 0){
      this.customId.begin = (vids as Array<any>)[0].vid
    }
    this.generateVidList()
  }

  public scannedEndId(vids: any) {
    if(vids.length > 0){
      this.customId.end = (vids as Array<any>)[0].vid
    }
    this.generateVidList()
  }

  public scannedVidFromQrcode(vids: any) {
    this.appendVids(vids)
  }

  public appendVids(vids: any) {
    let filteredVidList = vids.filter((item: any) => {
      var existedVidList = this.rfidData.filter((vidObj: any) => {
        return vidObj.vid.toLowerCase() === item.vid.toLowerCase()
      })
      return existedVidList.length <= 0
    })
    this.rfidData = this.rfidData.concat(filteredVidList)
    if(this.rfidData.length > this.vidCountLimit){
      this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
    }
    this.failRfidData = this.rfidData.filter(item => {
      return item.code !== 1
    })
  }

  public async generateVidList() {
    if(!this.customId.begin || !this.customId.end) {
      return
    }

    const list = await VeTrueApi.getUsableTagList({
      query: this.$route.query,
      project_uuid: this.$route.query.project_uuid,
      start_custom_id: this.customId.begin,
      end_custom_id: this.customId.end,
      label_type: 'PRODUCT_CODE' // 商品标签    PRODUCT_CODE      批次       BATCH_CODE
    });

    if (Object.keys(list).length === 0) {
      const messageString = this.$t("app.viderror.10001") as any
      this.$message({ message: messageString, type: "error", offset: 300, duration: 4000 , center: true });
      return
    } else {
      this.rfidData = list.map((item: any) => {
        return {
          vid: item.custom_id ? `${item.vid},${item.custom_id}`: item.vid, // 增加逻辑判断
          code: 1
        }
      });

      if (this.rfidData.length > this.vidCountLimit) {
        this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
      }
    }
  }

  // lifecycle
  created() {

  }

  mounted() {
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }

    const storedExtendList = this.$store.state.storedExtendList
    if (this.dcuKeyPath && storedExtendList) {
      let existExtendObj = storedExtendList.filter((item: any) => {
        return item.keyPath === this.dcuKeyPath
      })
      this.customId.begin = existExtendObj[0] && existExtendObj[0].startCode
      this.customId.end = existExtendObj[0] && existExtendObj[0].endCode
    }
    /// get start code end code from store
  }

  @Watch('$store.state.storedExtendList', {deep: true})
  onChange(oldVal: any, newVal: any) {
    const storedExtendList = this.$store.state.storedExtendList
    if(this.dcuKeyPath && storedExtendList){
      let existExtendObj = storedExtendList.filter((item: any) => {
        return item.keyPath === this.dcuKeyPath
      })
      this.customId.begin = existExtendObj[0] && existExtendObj[0].startCode
      this.customId.end = existExtendObj[0] && existExtendObj[0].endCode
    }
  }
}
