
























import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import callApp from "@/services/sdk"
import { BizType, Scene } from "@/utils/config";
import { shortVid, createRequestId } from '@/utils/vid'

@Component({
  components: {},
  filters:{}
})
export default class VidInput extends Vue {

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: ''})
  public dcuKeyPath!: string

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: ""})
  public vid!: string

  @Prop({default: ""})
  public vidScene!: string

  /// provide data
  @Provide() provideData: Object = {}

  private scannedVidList: Array<any> = []

  private vidContent: string = ''

  private get vidErrorStyle(): string {
    return "border-color: #dcdfe6"
  }

  // computed
  private get infos(): Array<any> {
    return Array<any>()
  }

  private get showContent(): string {
    if (this.scannedVidList.length <= 0) {
      return "";
    }
    const data = this.scannedVidList[0];
    if (data.customId) { // 芯片将返回customId
      return data.customId;
    }
    return shortVid(this.scannedVidList[0].vid);
  }

  private get isIos(): boolean {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  /// lifecycle
  created() {

  }

  mounted() {
    if (this.vid) {
      this.scannedVidList = [{vid: this.vid}]
    }
    console.log("this.scannedVidList", this.scannedVidList)
  }

  public scanVid() {
    if(this.formCannotEdit){
      return
    }

    let labelType = this.isBatchCode ? "BATCH_CODE" : "PRODUCT_CODE"
    let tagType = this.$route.query.scene === Scene.LOGISTIS_SEND ? 'BATCH_CODE' : labelType
    let applyno = this.$route.query.applyno || ""

    let data = {
        max: this.vidCountLimit,
        bizType: BizType,
        labelType: tagType,
        applyno,
        vidScene: this.vidScene,
        project_uuid: this.$route.query.project_uuid,
    }
    let scanOptions = this.selectedScannerOption
    if(scanOptions == 'qr.customid_range'){
      scanOptions = 'qr.customid_single'
    }
    let dataSourceType = `dataSource.${scanOptions}`
    const requestId = createRequestId();

    callApp("getData",{ type: dataSourceType, data }, "cbGetData", undefined, requestId)
      .then((resData: any) => {

        if (this.isIos) {
          this.scannedVidList = resData.vids
          this.$emit("scannedVid", this.scannedVidList)
        } else {
          (window as any)["web.setVidData" + requestId] = (data: any) => {
            if (this.vidCountLimit - this.scannedVidList.length < 0) {
              this.$toast({
                message: this.$t("app.scan_limit"),
                position: "center",
                iconClass: "icon el-icon-warning",
                duration: "3000"
              });
              return;
            }

            this.scannedVidList = data.vids
            console.log("scanned_resData", data, this.showContent, this.scannedVidList)
            this.$emit("scannedVid", this.scannedVidList)
          };
        }
      })
  }

  /// watch methods
  @Watch('vid', { immediate: true, deep: true })
  onArrayChanged(newVal: any, oldVal: any) {
    this.scannedVidList = [{vid: newVal}]
  }
}
