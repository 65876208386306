

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VidInput from '@/components/common/vidInput.vue'
import VidResultMulti from '@/components/common/vidResultMulti.vue'
import VidResultSingle from '@/components/common/vidResultSingle.vue'
import VidResultRange from '@/components/common/vidResultRange.vue'
import VidResultChip from '@/components/common/vidResultChip.vue'

@Component({
  components: {
    VidInput,
    VidResultMulti,
    VidResultSingle,
    VidResultRange,
    VidResultChip,
  },
  filters:{}
})
export default class VidResultList extends Vue {

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  private scannerType: string = ''

  public generateScannerType() {
    let nfcMulti = this.selectedScannerOption.startsWith('nfc') && this.vidCountLimit > 1
    if (this.selectedScannerOption === 'qr.customid_range') {
      this.scannerType = 'range'
    } else if (this.selectedScannerOption === 'qr.customid_single' || this.vidCountLimit == 1) {
      this.scannerType = 'single'
    } else if (this.selectedScannerOption === 'qr.customid_multi') {
      this.scannerType = 'multi'
    } else if (nfcMulti) {
      this.scannerType = 'chip'; // 芯片
    } else {
      this.scannerType = 'single'
    }
    console.log(this.scannerType, 'this.scannerType');
  }

  // methods
  public show() {
    (this as any).generateScannerType()
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).show();
    } else if (this.scannerType == 'multi') {
      (this.$refs.multiVidList as any).show();
    } else if (this.scannerType == 'range') {
      (this.$refs.rangeVidList as any).show();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).show();
    }
  }

  public clean() {
    (this as any).generateScannerType()
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).clean();
    } else if (this.scannerType == 'multi') {
      (this.$refs.multiVidList as any).clean();
    } else if (this.scannerType == 'range') {
      (this.$refs.rangeVidList as any).clean();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).clean();
    }
  }

  public dismiss(): void {
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).dismiss();
    } else if (this.scannerType == 'multi') {
      (this.$refs.multiVidList as any).dismiss();
    } else if (this.scannerType == 'range') {
      (this.$refs.rangeVidList as any).dismiss();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).dismiss();
    }
  }

  public confirm(vidlist: any) {
    this.$emit("confirm", vidlist)
  }

  public openSetting() {
    this.$emit('openSetting')
  }

  @Watch('selectedScannerOption', { immediate: true, deep: true })
  onArrayChanged(newVal: any, oldVal: any) {
    this.generateScannerType()
  }

  // lifecycle
  created() {

  }

  mounted() {
    this.generateScannerType()
  }
}
